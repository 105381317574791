import { IsServer } from 'util/browser'
import { env, type Env } from './constant'

export const BAS_API_PREFIX =
  env === 'master'
    ? 'https://bas.playsee.org/'
    : env === 'beta'
      ? `https://bas.playsee.one/`
      : `https://bas.playsee.dev/`

export const API_URIS = {
  // mkt
  SHARE_BY_CODE: 'web5.0.0/mkt/share_by_code',
  // users
  PROFILE_RECOMMEND_USER: 'web5.0.0/users/get_profile_recommend_user',
  // account
  NEW_PIXIE_GUEST: 'web5.0.0/accounts/new_pixie_guest',
  // post
  USER_SUGGESTED_POST_LIST: 'web5.0.0/post/get_user_suggested_post_list',
  // geo
  SUGGESTED_INSTREAM_BY_USER: 'web5.0.0/geo/get_suggested_instream_by_user'
} as const

type ApiUrl = typeof API_URIS
type ServerPrefixes = 'SRV0' | 'SRV1' | 'SRV2' | 'SRV50' | 'SRV10'

type Remove<
  T extends string,
  U extends string
> = T extends `${infer R}${U}${infer E}` ? `${R}${E}` : T

type ServerEnv = 'dev' | 'one' | 'org'
type PrefixApiUrl<Prefix extends ServerPrefixes> = Record<
  Prefix,
  {
    [Api in keyof ApiUrl]: `https://sv${Remove<
      Prefix,
      'SRV'
    >}.playsee.${ServerEnv}/${ApiUrl[Api]}`
  }
>

type IApiUrl = PrefixApiUrl<'SRV0'> &
  PrefixApiUrl<'SRV1'> &
  PrefixApiUrl<'SRV2'> &
  PrefixApiUrl<'SRV50'> &
  PrefixApiUrl<'SRV10'>

export const apiGenerator = (env: Env) => {
  return new Proxy(
    {},
    {
      get: (target, apiProp: keyof IApiUrl) => {
        return new Proxy(
          {},
          {
            get: (target, pathProp: keyof ApiUrl) => {
              const service = apiProp.replace('SRV', '')
              let serviceEnv = 'dev'
              switch (env) {
                case 'local':
                case 'develop':
                case 'stage':
                  serviceEnv = 'dev'
                  break
                case 'beta':
                  serviceEnv = 'one'
                  break
                case 'master':
                  serviceEnv = 'org'
                  break
              }
              return `https://sv${service}.playsee.${serviceEnv}/${API_URIS[pathProp]}`
            }
          }
        )
      }
    }
  ) as IApiUrl
}

export const API = apiGenerator(env)

export const MQTT_HOST =
  env === 'local'
    ? 'ws://34.123.107.93:8083/mqtt'
    : env !== 'master'
      ? env !== 'beta'
        ? 'wss://34.123.107.93:8083/mqtt'
        : 'wss://34.71.100.139:8083/mqtt'
      : 'wss://35.192.172.162:8083/mqtt'

const DEVELOP_API = {
  GEO: 'https://geo.playsee.dev/web/v1',
  CONTENT: 'https://content.playsee.dev/web/v1',
  CONTENT_V2: 'https://content.playsee.dev/web/v2',
  USER: 'https://user.playsee.dev/web/v1',
  ASSET: 'https://asset.playsee.dev/web/v1',
  NEWS_FEED: 'https://news-feed.playsee.dev/web/v1',
  NEWS_FEED_V2: 'https://news-feed.playsee.dev/web/v2',
  SESSION: 'https://session.playsee.dev'
} as const

const STAGE_API = {
  // https://geo.playsee.dev/swagger/index.html
  GEO: 'https://geo.playsee.dev/web/v1',
  // https://content.playsee.dev/swagger/index.html
  CONTENT: 'https://content.playsee.dev/web/v1',
  CONTENT_V2: 'https://content.playsee.dev/web/v2',
  // https://user.playsee.dev/swagger/index.html
  USER: 'https://user.playsee.dev/web/v1',
  // https://playsee.atlassian.net/wiki/spaces/backend/pages/137199664/Asset+service
  ASSET: 'https://asset.playsee.dev/web/v1',
  // https://news-feed.playsee.dev/swagger/index.html
  NEWS_FEED: 'https://news-feed.playsee.dev/web/v1',
  NEWS_FEED_V2: 'https://news-feed.playsee.dev/web/v2',
  SESSION: 'https://session.playsee.dev'
} as const

const BETA_API = {
  GEO: 'https://geo.playsee.one/web/v1',
  CONTENT: 'https://content.playsee.one/web/v1',
  CONTENT_V2: 'https://content.playsee.one/web/v2',
  USER: 'https://user.playsee.one/web/v1',
  ASSET: 'https://asset.playsee.one/web/v1',
  NEWS_FEED: 'https://news-feed.playsee.one/web/v1',
  NEWS_FEED_V2: 'https://news-feed.playsee.one/web/v2',
  SESSION: 'https://session.playsee.one'
} as const

const PROD_API = {
  GEO: 'https://geo.playsee.org/web/v1',
  CONTENT: 'https://content.playsee.org/web/v1',
  CONTENT_V2: 'https://content.playsee.org/web/v2',
  USER: 'https://user.playsee.org/web/v1',
  ASSET: 'https://asset.playsee.org/web/v1',
  NEWS_FEED: 'https://news-feed.playsee.org/web/v1',
  NEWS_FEED_V2: 'https://news-feed.playsee.org/web/v2',
  SESSION: 'https://session.playsee.org'
} as const

// K8S: micro-app-server
const K8S_API = {
  GEO: 'http://geo-service.micro-app-server/web/v1',
  CONTENT: 'http://content-service.micro-app-server/web/v1',
  CONTENT_V2: 'http://content-service.micro-app-server/web/v2',
  USER: 'http://user-service.micro-app-server/web/v1',
  ASSET: 'http://asset-service.micro-app-server/web/v1',
  NEWS_FEED: 'http://news-feed-service.micro-app-server/web/v1',
  NEWS_FEED_V2: 'http://news-feed-service.micro-app-server/web/v2',
  SESSION: 'http://session-service.micro-app-server'
} as const

export const apiV2Generator = (env: Env) => {
  if (IsServer() && process.env.NODE_ENV === 'production') {
    return K8S_API
  }
  switch (env) {
    case 'local':
    case 'develop':
      return DEVELOP_API
    case 'stage':
      return STAGE_API
    case 'beta':
      return BETA_API
    case 'master':
      return PROD_API
  }
}

export const API_V2 = apiV2Generator(env)
