import { PACKAGE_NAME, PACKAGE_VERSION } from 'constant'
import pino, { type Level, type LoggerOptions } from 'pino'

// Map Pino levels to Google Cloud Logging severity levels
// https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
export const CLOUD_LOGGING_SEVERITY_BY_LEVEL: {
  readonly [key in Level]: string
} = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL'
}

export interface AppContext {
  readonly name: string
  readonly version?: string
}

function gcpOptionsGenerator(appContext?: AppContext): Partial<LoggerOptions> {
  if (!appContext) {
    throw new Error('GCP target requires an app context')
  }

  const errorReportingFields = {
    '@type':
      'type.googleapis.com/google.devtools.clouderrorreporting.v1beta1.ReportedErrorEvent',
    serviceContext: {
      service: appContext.name,
      ...(appContext.version && { version: appContext.version })
    }
  }
  return {
    formatters: {
      level(level, _number) {
        const severity = CLOUD_LOGGING_SEVERITY_BY_LEVEL[level as Level]
        return {
          severity,
          ...(['error', 'fatal'].includes(level) && errorReportingFields)
        }
      },
      log(entry) {
        const error = (entry as any).err as Error | undefined
        return {
          ...entry,
          ...(error instanceof Error && { stack_trace: error.stack })
        }
      }
    },
    messageKey: 'message'
  }
}

let options: LoggerOptions = {
  level: 'debug'
}
if (process.env.NODE_ENV !== 'development') {
  options = {
    ...options,
    ...gcpOptionsGenerator({
      name: PACKAGE_NAME!,
      version: PACKAGE_VERSION
    })
  }
}

export const logger = pino(options)
