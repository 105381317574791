import { logger } from 'lib/logger'
import { ofetch, type $Fetch, type FetchOptions } from 'ofetch'
import { IsClient } from 'util/browser'

const toCurl = (request: RequestInfo, options: FetchOptions) => {
  const method = options.method || 'GET'

  let body = ''
  if (options.body) {
    if (typeof options.body === 'string') {
      body = `-d '${options.body}'`
    } else {
      body = `-d '${JSON.stringify(options.body)}'`
    }
  }
  const headers = new Headers(options.headers)
  let header = ''
  for (const [key, value] of headers.entries()) {
    header += `-H '${key}: ${value}' `
  }

  return `curl -X ${method} ${header} ${body} ${request}`
}

export const baseFetch = (
  getToken?: () => string | null | undefined
): $Fetch => {
  const fetcher = ofetch.create({
    async onRequest({ request, options }) {
      const headers = new Headers(options.headers)
      if (headers.has('Authorization')) {
        return
      }
      const token = getToken?.()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        options.headers = headers
      }
    },
    onResponseError({ request, response, options }) {
      if (IsClient()) {
        return
      }

      logger.error(
        {
          request: `${toCurl(request, options)}`,
          status: response.status
        },
        `[fetch response error]: ${request} ${response._data?.cause || response._data?.msg || ''}`
      )
    }
  })

  return fetcher
}
